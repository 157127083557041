import { Paginated } from '@feathersjs/feathers';
import { Field, Filter, Program } from '@wsb_dev/datafi-shared/lib/types';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { IStoreConfig } from '../../services/util/StoreManager';
import { ProgramSurvey } from '../../types/ProgramSurvey';
import { projectFilters } from '../../types/Project';
import { ensureNumber } from '../../util/numbers/ensureNumber';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { FilterDialog } from '../../components/filter-dialog/filter-dialog';
import { DialogService } from 'aurelia-dialog';
import { queryToFilter } from '@wsb_dev/datafi-shared/lib/util/fields/queryToFilter';
import { SurveyFieldEdit } from '../../pages/dashboard-program-edit/survey-field-edit/survey-field-edit';

@autoinject
export class DashboardStoreConfig {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: IStoreConfig;
    @bindable programId: number;
    @bindable surveyId: number;
    @bindable offlineService: boolean;
    @bindable mapGeometry: boolean;

    filters: Filter[];
    filtersWithValue: number;
    program: Program;
    dfpSurveys: ProgramSurvey[];
    selectedSurvey: ProgramSurvey;
    selectedFields: string[];

    constructor(
        private api: DatafiProAPI,
        private dialogService: DialogService,
    ) { }

    async bind() {
        this.programId = ensureNumber(this.programId);
        this.program = await this.api.programs.get(this.programId);

        const result = await this.api.programSurveys.find({
            query: {
                $select: ['id', 'title', 'surveySchema', 'survey_type'],
                program_id: this.programId,
                $limit: 100,
            },
        }) as Paginated<ProgramSurvey>;
        this.dfpSurveys = result.data.filter((sv) => sv.survey_type === 'dfp-form');
        this.value.query = this.value.query || {};
        this.value.query.$limit = this.value.query.$limit || 5000;

        if (this.value.query.survey_id) {
            this.surveyId = this.value.query.survey_id;
            this.surveyIdChanged(this.surveyId);
        }

        this.mapGeometry = !!this.value.query.$modify?.toGeoJSON;
        this.offlineService = !this.value.query.$client;
        this.selectedFields = this.value.query.$modify?.fieldSelection;

        this.setupFields();
        this.setupQuery();
    }

    surveyIdChanged(id: number) {
        this.selectedSurvey = this.dfpSurveys.find((survey) => survey.id === id);
    }

    setupFields() {
        let filters = [];
        if (this.value.service === 'api/v1/projects') {
            filters = this.program.projectSchema.length ? [
                ...projectFilters,
                {
                    type: 'object',
                    name: 'metadata',
                    label: 'Project Fields',
                    fields: this.program.projectSchema,
                },
            ] : [...projectFilters];
        } else if (this.value.service === 'api/v1/form-submissions') {
            if (!this.selectedSurvey) {
                this.surveyIdChanged(this.value.query.survey_id);
            }
            filters = this.selectedSurvey?.surveySchema || [];
        }
        this.filters = queryToFilter(flattenFields(filters), [], this.value.query);
        this.setActiveFilters();
    }

    updateFilters() {
        return this.dialogService.open({
            viewModel: FilterDialog,
            model: { filterProperties: this.filters },
        }).whenClosed((result) => {
            if (result.wasCancelled) return;
            this.filters = result.output;
            this.setActiveFilters();
            this.updateValue(result.output);
        });
    }

    resetFilters() {
        const filters = this.filters.map((filter) => {
            if (filter.type === 'object') {
                Object.keys(filter.value).forEach((index) => {
                    filter.value[index] = null;
                });
            }
            else {
                filter.value = undefined;
            }
            return filter;
        });
        this.filters = filters;
        this.setActiveFilters();
        this.updateValue(filters);
    }

    batchSelectFields() {
        return this.dialogService.open({
            viewModel: SurveyFieldEdit,
            model: { model: this.selectedSurvey.surveySchema as Field[], fieldSelection: this.selectedFields, customFieldSelection: true },
        }).whenClosed((result) => {
            if (result.wasCancelled) return;
            this.value.query.$modify = {
                ...this.value.query.$modify,
                fieldSelection: flattenFields(result.output)
                    .filter((f) => f.visibility.list)
                    .map((f) => f.path),
            };

            this.selectedFields = this.value.query.$modify.fieldSelection.length ? this.value.query.$modify.fieldSelection : undefined;
        });
    }

    resetSelectFields() {
        this.value.query.$modify.fieldSelection = undefined;
        this.selectedFields = undefined;
    }

    setupQuery() {
        this.value.query.$eager = this.value.service === 'api/v1/programs-users-roles' ? '[user]' : undefined;
    }

    reset() {
        this.filters = undefined;
        this.value = {
            ...this.value,
            query: {
                survey_id: this.surveyId,
                program_id: this.programId,
                $limit: 5000,
            },
        };
        this.offlineServiceChanged();
        this.mapGeometryChanged();
        this.setupFields();
        this.setupQuery();
    }

    mapGeometryChanged() {
        this.value.query.$modify = this.value.query.$modify || {};
        if (this.mapGeometry) {
            this.value.query.$modify.toGeoJSON = [];
        } else {
            delete this.value.query.$modify.toGeoJSON;
        }
    }

    offlineServiceChanged() {
        if (this.offlineService) {
            delete this.value.query.$client;
        } else {
            this.value.query.$client = 'no dashboard';
        }
    }

    updateValue(filters: Filter[]) {
        const programId = this.value.query?.program_id;
        const filterQuery = filtersToQuery(filters);
        const fieldSelection = this.value.query?.$modify?.fieldSelection;
        const includeSurveyId = this.value.service === 'api/v1/form-submissions';

        this.value = {
            ...this.value,
            query: {
                ...filterQuery,
                program_id: programId,
                ...(includeSurveyId && { survey_id: this.surveyId }),
                $modify: {
                    fieldSelection: fieldSelection || [],
                    ...filterQuery.$modify,
                },
            },
        };

        this.offlineServiceChanged();
        this.mapGeometryChanged();
        this.value = this.removeEmptyObjects(this.value);
    }

    removeEmptyObjects(data: any) {
        for (const key in data) {
            const item = data[key];
            if (typeof item === 'object' && item !== null) {
                this.removeEmptyObjects(item);
                if (Object.keys(item).length === 0 && key !== 'toGeoJSON' && key !== 'checkboxQuery') {
                    delete data[key];
                }
            }
        }
        return data;
    }

    setActiveFilters() {
        this.filtersWithValue = this.filters?.filter((f) => {
            return f.value && !(Array.isArray(f.value) && f.value.length === 0) && (f.type !== 'number' || f.value.first?.value);
        }).length;
    }
}
