import { Filter } from '@wsb_dev/datafi-shared/lib/types';
import { bindable, bindingMode } from 'aurelia-framework';
import { DateOptions } from '../filter-dialog/filter-dialog';

export class FilterBuilder {
    @bindable({mode: bindingMode.twoWay}) filters: Filter[] = [];
    dateOptionsTypes = ['single', 'range'];
    dateOptions: Record<string, DateOptions> = {
        single: {
            mode: 'single', name: 'Single Date', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
        range: {
            mode: 'range', name: 'Date Range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
    }
    operatorOptions = [
        { label: 'equals', value: '$eq' },
        { label: 'does not equal', value: '$not' },
        { label: 'less than', value: '$lt' },
        { label: 'less than or equal to', value: '$lte' },
        { label: 'greater than', value: '$gt' },
        { label: 'greater than or equal to', value: '$gte' },
    ]
    compareOptions = ['and', 'or']

    clearSecondOp(filter) {
        if (!filter.value.first?.value || !filter.value.first.operator || (filter.value.first.operator === '$eq' && filter.value.compare !== 'or')) {
            filter.value.second = undefined;
        }
        return filter;
    }

    filtersChanged() {
        if (this.filters) {
            this.filters.forEach((filter) => {
                switch (filter.type) {
                case 'date': {
                    if (!filter.selectedDateOptions) filter.selectedDateOptions = 'single';
                    break;
                }
                case 'number':
                    if (!filter.value) {
                        filter.value = {
                            first: { operator: '$eq' },
                        };
                    }
                    if (typeof filter.value === 'string') {
                        filter.value = {
                            first: { value: filter.value, operator: '$eq' },
                        };
                    } else {
                        const { first, second } = filter.value;
                        if (!first?.value)
                            filter.value.first = { operator: '$eq' };
                        if (!second?.value) {
                            filter.value.second = undefined;
                        }
                    }

                    break;
                case 'checkbox':
                    if (!filter.value) {
                        filter.value = [];
                    }
                    break;
                case 'object':
                    if (!filter.value) {
                        filter.value = {};
                    }
                    break;
                }
            });
        }
    }
}
